<template>
  <div>
    <div class="staticHero">
      <v-img src="../assets/images/AboutHero.png"> </v-img>
    </div>
    <div class="block">
      <v-container>
        <h2>
          <strong>{{ $t("aboutUsHeading") }}</strong>
        </h2>
        <p v-html="this.$sanitize($t('aboutUsBody'))"></p>
      </v-container>
    </div>
  </div>
</template>

<script>
import Landing from "@/layouts/Landing";
export default {
  name: "About",
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>

<style scoped>
br {
  line-height: 150%;
}
</style>
